<template>
  <div>
    <form class="row" @submit.prevent="save()">
      <div class="col-lg-12 col-md-12 col-12">
        <InfoBlock
            @onInputHandler = "onInputHandler"
        />
      </div>
      <div class="col-lg-12 col-md-12 col-12 mt-3">
        <PointBlock
            @onInputHandler = "onInputHandler"
        />
      </div>
    </form>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      point: {
        organization: null,
        stock: null,
        transport: null,
        driver: null,
        date: null,
        time: null,
        weight: 0,
        volume: 0,
        direction_id: null,
        points: [],
      }
    }
  },
  components: {
    InfoBlock: () => ({
      component: import("@/components/add/point/info"),
    }),
    PointBlock: () => ({
      component: import("@/components/add/point/points"),
    }),
  },
  methods: {
    onInputHandler(e) {
      this.point[e.key] = e.param
    },
    async save() {
      const formData = new FormData();
      formData.set("organization", this.point.organization);
      formData.set("stock", this.point.stock);
      formData.set("transport", this.point.transport);
      formData.set("driver", this.point.driver);
      formData.set("date", this.point.date);
      formData.set("time", this.point.time);
      formData.set("driver", this.point.driver);
      formData.set("direction_id", this.point.direction_id);

      this.point.points.forEach((item, k) => {
          Object.entries(item).forEach(([key, value]) => {
              formData.append("points["+k+"]["+key+']', value);
          });
      })

      let order_id = null;

      const url = "/web/order-new";
      await this.$api
        .post(url, formData)
        .then((response) => {
          order_id = response.data.data.id;
          window.open(this.$url+'/storage/temp_ttn/'+'temp_ttn-'+order_id+'.pdf', '_blank');
          window.open(this.$url+'/storage/temp_pass/'+'temp_pass-'+order_id+'.pdf', '_blank');
          this.$router.push({name: 'Route_show', params: {id: order_id}})
          this.$toast.success("Успешно!");
        })
        .catch(() => {
          this.$toast.error('Что-то пошло не так, попробуйте позднее!');
        });

      // axios.get(this.$url+'/storage/temp_ttn/'+point_id+'.pdf', {responseType: 'blob'})
      //   .then((response) => {
      //       const url = window.URL.createObjectURL(new Blob([response.data]));
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.setAttribute('download', 'temp_ttn.pdf');
      //       document.body.appendChild(link);
      //       link.click();
      //   }).catch((e) => {
      //     this.$toast.error(e);
      //   });
    },
  },
};
</script>

<style scoped></style>
